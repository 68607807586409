<template>
<div :class="[ 'circle-container flex items-center justify-center rounded-full relative', computedClass]">
    <img v-if="avatarImage" class="object-cover w-full h-full" :src="avatarImage" />
    <div v-else :class="['text-white bg-blue-200 ', textSize]" >
        {{ getInitials() }}
    </div>
</div>
<div v-if="showStatus"
class="w-2.5 h-2.5 z-100 rounded-full border border-white bottom-0.5 right-0.5 absolute"
     :class="{
        'bg-[#22C55E]': status === 'online',
        'bg-white': status !== 'online'
     }"
>
<div
class="border-2 border-[#919EAB] w-full h-full rounded-full"
:class="{'border-transparent': status==='online'}"
>
</div>
</div>
</template>
<script>
export default {
    props: {
        class:{
            type:String,
            default:'',
        },
        size: {
            type:String,
            default:8,
        },
        user:{
            type:Object,
            default: null
        },
        showStatus: {
            type: Boolean,
            default: false
        },
        has_shadow:{
            type: Boolean,
            default: false,
        },

        postOrganization:{
            type: Object,
            default: null,
            required:false
        },

        countryAvatar:{
            type: String,
            default: null,
            required:false
        }

    },
    data(){
        return{
            path: import.meta.env.VITE_APP_URL,
            status: 'offline'
        };
    },
    computed: {
        computedClass(){
            return  (this.has_shadow ? ' shadow' :'') +' w-' + this.size + ' h-' + this.size + ' bg-blue-200 ' + this.class;
        },
        textSize(){
            let size;
            if (this.size > 16) {
                size =  'text-4xl';
            }
            if (this.size > 14) {
                size =  'text-3xl';
            }
            else if (this.size > 10) {
                size =  'text-2xl';
            }
            else if (this.size > 8) {
                size =  'text-xl';
            }
            else {
                size =  'text-md';
            }
            return size;
        },
        avatarImage() {
            if(this.countryAvatar !== null){
                return this.path + this.countryAvatar;
            }
            // Check if postOrganization exists and has a logo
            if (this.postOrganization && this.postOrganization.logo) {
                return this.path + this.postOrganization.logo;
            }

            // console.log(this.userData?.profile_avatar?.url);

            // Check if userData exists and has an avatar
            if (this.userData && (this.userData.avatar || this.userData.profile_avatar)) {
                return this.path +'/' + (this.userData?.profile_avatar?.url || this.userData.avatar);
            }

            // Return empty string if neither condition is met
            return '';
        },
        userData() {
            return this.user || this.$page.props.auth.user;
        },
    },
    methods:{
        getInitials() {
            const firstname = this.userData?.firstname;
            const lastname = this.userData?.lastname;
            if (!firstname && !lastname) return ' ';
            return `${firstname[0]}${lastname[0]}`.toUpperCase();
        },
        getUserStatus() {
            axios.get(route('chat.user.get.status', this.user.id) )
                .then((response) => {
                    this.status = response.data.status;
                })
        }
    },
    mounted() {
        if (this.showStatus) {
            this.getUserStatus();
        }
    }
}
</script>
<style>
 .circle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
  }
</style>
